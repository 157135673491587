import React from "react"
import { Helmet } from "react-helmet"

import Header from "../components/Header/header"
import Footer from "../components/Footer/footer"
import * as styles from "../styles/contact-us.module.css"

const ContactUs = () => {
    return (
        <React.Fragment>
            <Helmet title="Silver Oaks - Contact Us" />

            <body>
                <Header
                    invert={true}
                    title="Silver Oaks International Limited"
                />

                <div className={styles.container}>
                    <div className={styles.content}>
                        <h1>Contact Us</h1>

                        <p>
                            Contact us to learn how we can add value to your
                            business
                        </p>

                        <address>
                            <br /> Silver Oaks International Limited <br />
                            New Zealand <br />
                        </address>

                        <a href="mailto:hafeez@silveroaks.nz">
                            hafeez@silveroaks.nz
                        </a>

                        <a href="tel:+64-9-21667937">+64-9-21667937</a>
                    </div>
                </div>

                <div style={{ marginTop: "auto" }}>
                    <Footer />
                </div>
            </body>
        </React.Fragment>
    )
}

export default ContactUs
